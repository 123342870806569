import { styled } from 'styled-components';
import { colours } from '../../constants';

export const NavigationWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: ${colours.primaryLight};
  z-index: 10;
`;

export const NavigationItems = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 25px;
  padding: 40px 60px;
  max-width: 1400px;
  margin: 0 auto;
`;
