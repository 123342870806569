import { useEffect, useRef, useState } from 'react';
import { useInViewport } from 'react-in-viewport';

const useFadeIn = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport } = useInViewport(ref);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (inViewport) {
      setIsLoaded(true);
    }
  }, [inViewport]);

  return {
    ref,
    styles: {
      opacity: isLoaded ? '1' : '0',
      transition: 'opacity 500ms 100ms',
    },
  };
};

export default useFadeIn;
