import { WorkItem } from './types';

export const colours = {
  primaryLight: '#fefefe',
  primaryDark: '#101022',
  secondaryDark: '#606082',
};

export const workItems: WorkItem[] = [
  {
    company: 'Colony',
    date: '04.24 - Present',
    jobTitle: 'Senior full stack engineer',
    image: 'colony.jpg',
    id: 'colony',
    skills: [
      'React',
      'Typescript',
      'GraphQL',
      'Docker',
      'AppSync',
      'Amplify',
      'AWS',
      'Node',
      'Webpack',
      'CSS',
      'Git',
      'EthersJS',
      'Blockchain',
    ],
    shortDescription:
      'I am working as a senior engineer in the blockchain and cryptocurrency space, working for a fully remote startup with an exciting open source product.',
    longDescription:
      'Currently working at Colony, a Web 3.0 startup focussed on helping internet organisations handle their finances and governance. I am working as a senior member of the DApp development team, using React, Typescript, GraphQL, EthersJS, Docker and Node.',
  },
  {
    company: 'Stitch',
    date: '03.22 - 03.24',
    jobTitle: 'Senior full stack engineer',
    image: 'stitch.jpg',
    id: 'stitch',
    skills: [
      'React',
      'Typescript',
      'GraphQL',
      'Go',
      'Docker',
      'Postgres',
      'Node',
      'Webpack',
      'ThreeJS',
      'Kubernetes',
      'CSS',
      'Git',
    ],
    shortDescription:
      'After a few years of focussing only on the frontend, I decided that I wanted to learn more, and become a full stack engineer.',
    longDescription:
      'After a couple of years working here, I decided to expand my technical expertise and start working across the entire tech stack. I started working on deployment and cloud-computing based work, using tools such as Kubernetes, Github Actions, AWS and Terraform. I then, with the support of my team, started to pick up tickets in the backend of our application, using Go, gRPC, GraphQL and Postgres. I also took courses in multiple back end topics to broaden my base knowledge, including Python, Java, Data Structures & Algorithms, and Databases. At the same time I continued to work in the front end, so my title changed to Senior Full Stack Engineer.',
  },
  {
    company: 'Hatch Studio',
    date: '07.19 - 03.22',
    jobTitle: 'Front end engineer',
    image: 'hatch.jpg',
    id: 'hatch',
    skills: [
      'React',
      'Typescript',
      'GraphQL',
      'Docker',
      'Node',
      'Webpack',
      'ThreeJS',
      'CSS',
      'Git',
    ],
    shortDescription:
      'I joined a startup in the fashion tech industry from a very early stage, and grew with the company.',
    longDescription:
      'I joined HATCH as a front end developer to essentially replace the outgoing front end development team. I took over a product in its infancy, and built the front end of this complicated software alongside a team of developers which I interviewed and hired. The product was a digital showroom for fashion brands, including the use of touchtables, remote video conferencing and external screens connected via websockets to allow fashion wholesalers to present new collections. The product presented an interesting challenge, as it has to combine a luxury, smooth user experience with a heavy and complex data set, and all the while be flexible enough for multiple brands to use. On the front end side technically we used React written in Typescript (built with Webpack), with GraphQL through Apollo Client for communication with the back end. We used Storybook and Figma for design collaboration, as well as Enzyme and Cypress for testing.',
  },
  {
    company: 'Villa Baltic Hotel',
    date: '05.18 - 06.18',
    jobTitle: 'Freelance project',
    image: 'villa.jpg',
    id: 'villa',
    skills: ['Wordpress', 'Javascript', 'CSS', 'HBook plugin'],
    shortDescription:
      'In the evenings and weekends, I picked up a small project using wordpress to build a site which needed to include a booking engine and payments.',
    longDescription:
      'I worked alongside my wife to design and create a new website for this small beachside aparthotel in northern Poland. I used Wordpress with a booking plugin called HBook to handle the calendar, and integrated with Stripe for payments. I customized all of the CSS on the site, instead of using a template.',
  },
  {
    company: 'Appical',
    date: '04.18 - 06.19',
    jobTitle: 'Front end developer',
    image: 'appical.jpg',
    id: 'appical',
    skills: [
      'React',
      'Javascript',
      'REST',
      'AWS',
      'Webpack',
      'Scrum',
      'CSS',
      'Git',
    ],
    shortDescription:
      'I worked as the only web developer in my team for a fast growing startup in Amsterdam working in the HR tech space.',
    longDescription:
      'Built the new progressive webapps from scratch for this HR Tech startup using React and JSS. Built features including push notifications and real-time messaging. Built two webapps whilst collaborating with a remote back end API team in Pakistan and a native mobile team, ensuring alignment on all features and changes. Used Enzyme and Jest for unit testing, Storybook to create reusable components, and CircleCI with AWS for integration and deployment.',
  },
  {
    company: 'Metoda JS',
    date: '12.17 - 01.18',
    jobTitle: 'Freelance project',
    image: 'metoda.jpg',
    id: 'metoda',
    skills: ['Wordpress', 'Javascript', 'CSS', 'HTML Canvas'],
    shortDescription:
      'In the evenings and weekends, I picked up a small project using wordpress to build a static site.',
    longDescription:
      'I worked alongside my wife to design and create a new website for this independently working holistic health and diet coach. I used Wordpress, and customized all of the CSS on the site instead of using a template. I used HTML canvas to create some interesting animations and interactive elements.',
  },
  {
    company: 'Salmon',
    date: '09.16 - 03.18',
    jobTitle: 'Graduate front end developer',
    image: 'salmon.jpg',
    id: 'salmon',
    skills: ['Javascript', 'Java', 'Node', 'Grunt', 'Scrum', 'CSS', 'Git'],
    shortDescription:
      'My first job was at an ecommerce consultancy based outside of London. I worked on the PVH account building the online shops for Tommy Hilfiger Europe and Calvin Klein Europe.',
    longDescription:
      'After completing my three month training a month early, I moved onto a project team, becoming a member of the front end team working for a major client, PVH. Worked on the front-end part of a replatform project for Calvin Klein and Tommy Hilfiger onto Websphere 8, mainly using jQuery, SASS, JSTL, Node and Grunt. Quickly established myself as an important and trusted developer in the team, and worked across all areas of the ecommerce sites, involving both defects and change requests. Supported the hypercare period during go-live, including night shifts to respond to any issues quickly. Later moved onto a smaller team for a redesign project on Tommy Hilfiger, and switched to an agile approach using scrum. During this time I was largely based at the client site, and helped to establish a good working relationship between the client and our team. During this I project I led multiple sprint demos to high level stakeholders. Also worked separately in a team of two developers, creating a tool from scratch for generating platform-agnostic Pattern Libraries. This enabled teams working on various tech stacks for different clients to create a library of reusable components, stopping code from being rewritten and keeping coding standards in place.',
  },
  {
    company: 'Univeristy of York',
    date: '10.12 - 08.16',
    jobTitle: 'Masters of Engineering',
    image: 'york.jpg',
    id: 'york',
    skills: [],
    shortDescription:
      'At the University of York, England, I studied a Masters degree in Electronic Engineering with Music Technology Systems',
    longDescription:
      'I studied this course as it was a perfect blend of creativity and engineering. About a third of the course was focussed on music and the recording industry, learning how to properly record music and sound, learning the science of how sound worked, and learning about the ins and outs of a recording studio. The rest of the course was split between advanced mathematics, electronic hardware engineering and software engineering. In software, we learnt the basics of C, C++, Java and Swift.',
  },
];
