import { FC } from 'react';
import {
  SkillsScreenTitle,
  SkillsScreenSubtitle,
  SkillsScreenWrapper,
} from './SkillsScreen.sc';
import useFadeIn from '../../hooks/useFadeIn';
import SkillsList from '../../components/SkillsList/SkillsList';

const SkillsScreen: FC = () => {
  const { ref, styles } = useFadeIn();

  const skills = [
    'React',
    'Vue',
    'Angular',
    'Typescript',
    'GraphQL',
    'Go',
    'Docker',
    'Postgres',
    'AWS',
    'Node',
    'Webpack',
    'Scrum',
    'ThreeJS',
    'Kubernetes',
    'CSS',
    'Git',
    'EthersJS',
    'Blockchain',
  ];

  return (
    <SkillsScreenWrapper id="stack" ref={ref} style={styles}>
      <SkillsScreenTitle>Technical stack</SkillsScreenTitle>
      <SkillsScreenSubtitle>
        I have experience with and am comfortable using many technologies and
        methodologies. These include but are not limited to:
      </SkillsScreenSubtitle>
      <SkillsList skills={skills} />
    </SkillsScreenWrapper>
  );
};

export default SkillsScreen;
