import { FC } from 'react';
import {
  WorkItemWrapper,
  WorkItemImage,
  WorkItemImageWrapper,
  WorkItemTitle,
  WorkItemDetails,
  WorkItemJob,
  WorkItemDate,
} from './WorkItem.sc';
import useFadeIn from '../../hooks/useFadeIn';
import { WorkItem as WorkItemInterface } from '../../types';
import { useNavigate } from 'react-router-dom';

interface Props {
  workItem: WorkItemInterface;
}

const WorkItem: FC<Props> = ({
  workItem: { company, date, jobTitle, image, id },
}) => {
  const { ref, styles } = useFadeIn();
  const navigate = useNavigate();

  const href = `/experience/${id}`;

  const handleClick = () => {
    navigate(href);
  };

  return (
    <WorkItemWrapper style={styles} ref={ref}>
      <WorkItemImageWrapper onClick={handleClick}>
        <WorkItemImage src={`${process.env.PUBLIC_URL}/${image}`} />
      </WorkItemImageWrapper>
      <WorkItemDetails>
        <WorkItemJob>
          <a href={href}>{jobTitle}</a>
        </WorkItemJob>
        <WorkItemDate>
          <a href={href}>{date}</a>
        </WorkItemDate>
      </WorkItemDetails>
      <WorkItemTitle>
        <a href={href}>{company}</a>
      </WorkItemTitle>
    </WorkItemWrapper>
  );
};

export default WorkItem;
