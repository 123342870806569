import { FC, useEffect, useRef, useState } from 'react';
import { SkillsListSkill, SkillsListSkills } from './SkillsList.sc';
import ConfettiExplosion from 'react-confetti-explosion';

const generateRandomNumber = (min: number, max: number) => {
  return Math.random() * (max - min + 1) + min;
};

const getRandomColour = () => {
  let result = [];
  let hexRef = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
  ];

  for (let n = 0; n < 6; n++) {
    result.push(hexRef[Math.floor(Math.random() * 16)]);
  }
  return '#' + result.join('');
};

const CONFETTI_DURATION = 3000;
const MIN_FORCE = 0.5;
const MAX_FORCE = 0.8;

const MIN_PARTICLE_COUNT = 100;
const MAX_PARTICLE_COUNT = 300;

const MIN_WIDTH = 1000;
const MAX_WIDTH = 2000;

const NUMBER_OF_COLOURS = 5;

const generateConfetti = () => {
  return {
    force: generateRandomNumber(MIN_FORCE, MAX_FORCE),
    duration: CONFETTI_DURATION,
    particleCount: generateRandomNumber(MIN_PARTICLE_COUNT, MAX_PARTICLE_COUNT),
    width: generateRandomNumber(MIN_WIDTH, MAX_WIDTH),
    colors: [...new Array(NUMBER_OF_COLOURS).fill(null).map(getRandomColour)],
  };
};

interface Props {
  skills: string[];
}

const SkillsList: FC<Props> = ({ skills }) => {
  const timeout = useRef<NodeJS.Timeout>();
  const [isExploding, setIsExploding] = useState<number | null>(null);

  const handleSkillClick = (index: number) => {
    if (isExploding === index) return;

    setIsExploding(index);

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      setIsExploding(null);
    }, 3000);
  };

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  const confetti = generateConfetti();

  return (
    <SkillsListSkills>
      {skills.map((skill, index) => (
        <SkillsListSkill onClick={() => handleSkillClick(index)}>
          {skill}
          {isExploding === index && <ConfettiExplosion {...confetti} />}
        </SkillsListSkill>
      ))}
    </SkillsListSkills>
  );
};

export default SkillsList;
