import { FC, useEffect, useRef, useState } from 'react';
import {
  ContactScreenWrapper,
  ContactScreenInner,
  ContactScreenTitle,
  ContactScreenSubtitle,
  ContactScreenLinks,
} from './ContactScreen.sc';
import useFadeIn from '../../hooks/useFadeIn';

const texts = ['work', 'create', 'chat', 'build'];

interface State {
  text: string;
}

const ContactScreen: FC = () => {
  const { ref, styles } = useFadeIn();

  const textIndex = useRef<number>(0);
  const currentText = useRef<string>(texts[textIndex.current]);
  const spanRef = useRef<HTMLSpanElement>(null);
  const timeout = useRef<any>(null);

  const [text, setText] = useState<State['text']>(texts[textIndex.current]);

  const removeText = () => {
    if (currentText.current.length > 0) {
      currentText.current = currentText.current.substring(
        0,
        currentText.current.length - 1
      );

      setText(currentText.current);

      clearTimeout(timeout.current);
      setTimeout(removeText, 50);
    } else {
      textIndex.current =
        textIndex.current + 1 > texts.length - 1 ? 0 : textIndex.current + 1;

      addText();
    }
  };

  const addText = () => {
    if (currentText.current.length < texts[textIndex.current].length) {
      currentText.current =
        currentText.current +
        texts[textIndex.current].charAt(currentText.current.length);

      setText(currentText.current);

      clearTimeout(timeout.current);
      setTimeout(addText, 50);
    } else {
      clearTimeout(timeout.current);
      setTimeout(removeText, 2000);
    }
  };

  useEffect(() => {
    timeout.current = setTimeout(() => {
      removeText();
    }, 2000);

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  });

  return (
    <ContactScreenWrapper id="contact" ref={ref} style={styles}>
      <ContactScreenInner>
        <ContactScreenTitle>Contact</ContactScreenTitle>
        <ContactScreenSubtitle>
          Want to <span ref={spanRef}>{text}</span> with me?
        </ContactScreenSubtitle>
        <ContactScreenLinks>
          <p>
            - Give me a shout via{' '}
            <a href="mailto:david.creaser@outlook.com">email</a>.
          </p>
          <p>
            - Check out my profile, or connect with me on{' '}
            <a
              href="https://www.linkedin.com/in/david-creaser/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
            .
          </p>
          <p>
            - Take a look at my contributions on{' '}
            <a
              href="https://github.com/davecreaser"
              target="_blank"
              rel="noopener noreferrer"
            >
              Github
            </a>
            .
          </p>
          <p>
            - Go old school, and download a copy of my{' '}
            <a
              href={process.env.PUBLIC_URL + '/david_creaser_cv.pdf'}
              download="david_creaser_cv.pdf"
            >
              CV
            </a>
            .
          </p>
        </ContactScreenLinks>
      </ContactScreenInner>
    </ContactScreenWrapper>
  );
};

export default ContactScreen;
