import { styled } from 'styled-components';
import { colours } from '../../constants';

export const PhotosScreenWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto 200px;
  padding: 150px 50px 0px;
`;

export const PhotosScreenTitle = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const PhotosScreenSubtitle = styled.h3`
  color: ${colours.secondaryDark};
  margin-bottom: 20px;

  > a {
    color: ${colours.primaryDark};
    text-decoration: underline;
  }
`;

export const PhotosScreenPreview = styled.div`
  height: 800px;
  overflow: hidden;
  position: relative;
`;

export const PhotosScreenSeeMore = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0,
    ${colours.primaryLight} 50%
  );
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  padding-bottom: 25px;
  pointer-events: none;

  > a {
    font-size: 1rem;
    color: ${colours.secondaryDark};
    text-decoration: underline;
    cursor: pointer;
    pointer-events: initial;
  }
`;
