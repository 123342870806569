import { FC } from 'react';
import useFadeIn from '../hooks/useFadeIn';
import { colours, workItems } from '../constants';
import Navigation from '../components/Navigation/Navigation';
import { useNavigate, useParams } from 'react-router-dom';
import ExperienceScreen from '../screens/ExperienceScreen/ExperienceScreen';

const Experience: FC = () => {
  const { ref, styles } = useFadeIn();

  const navigate = useNavigate();
  const { experience } = useParams();

  const workItem = workItems.find((workItem) => workItem.id === experience);

  if (!workItem) {
    navigate('/404');

    return null;
  }

  return (
    <div
      style={{
        color: colours.primaryDark,
        ...styles,
      }}
      ref={ref}
    >
      <div>
        <Navigation subpage="experience" />
        <ExperienceScreen workItem={workItem} />
      </div>
    </div>
  );
};

export default Experience;
