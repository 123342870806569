import { styled } from 'styled-components';
import { colours } from '../../constants';

export const SkillsListSkills = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 0;
`;

export const SkillsListSkill = styled.button`
  background: ${colours.primaryDark};
  color: ${colours.primaryLight};
  border-radius: 2rem;
  padding: 10px 20px;
  outline: none;
  border: 2px solid ${colours.primaryDark};
  cursor: pointer;

  &:hover {
    background: ${colours.primaryLight};
    color: ${colours.primaryDark};
  }
`;
