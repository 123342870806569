import { FC } from 'react';
import WorkItem from '../../components/WorkItem/WorkItem';
import {
  WorkScreenWrapper,
  WorkScreenTitle,
  WorkScreenSubtitle,
  WorkItems,
} from './WorkScreen.sc';
import useFadeIn from '../../hooks/useFadeIn';
import { workItems } from '../../constants';

const WorkScreen: FC = () => {
  const { ref, styles } = useFadeIn();

  return (
    <WorkScreenWrapper id="experience" ref={ref} style={styles}>
      <WorkScreenTitle>Experience</WorkScreenTitle>
      <WorkScreenSubtitle>
        I have over eight years of industry experience, working in various team
        setups including alone, and in both startup and corporate settings.
      </WorkScreenSubtitle>
      <WorkItems>
        {workItems.map((item) => (
          <WorkItem workItem={item} key={item.company} />
        ))}
      </WorkItems>
    </WorkScreenWrapper>
  );
};

export default WorkScreen;
