import { FC } from 'react';
import { useRouteError } from 'react-router-dom';
import { colours } from './constants';

const ErrorPage: FC = () => {
  const error: any = useRouteError();
  console.error(error);

  return (
    <div
      id="error-page"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '20px',
        padding: '200px 0',
      }}
    >
      <h1 style={{ fontSize: '2rem' }}>Oops!</h1>
      <p
        style={{
          fontSize: '1rem',
          color: colours.secondaryDark,
          marginBottom: '40px',
        }}
      >
        Sorry, an unexpected error has occurred.
      </p>
      <p>
        <a href="/" style={{ fontSize: '1rem', color: colours.secondaryDark }}>
          Return home
        </a>
      </p>
    </div>
  );
};

export default ErrorPage;
