import { FC } from 'react';
import useFadeIn from '../hooks/useFadeIn';
import { colours } from '../constants';
import Navigation from '../components/Navigation/Navigation';
import ContactScreen from '../screens/ContactScreen/ContactScreen';
import LandingScreen from '../screens/LandingScreen/LandingScreen';
import PhotosScreen from '../screens/PhotosScreen/PhotosScreen';
import SkillsScreen from '../screens/SkillsScreen/SkillsScreen';
import WorkScreen from '../screens/WorkScreen/WorkScreen';
import { ScrollRestoration } from 'react-router-dom';

const Main: FC = () => {
  const { ref, styles } = useFadeIn();

  return (
    <div
      style={{
        color: colours.primaryDark,
        ...styles,
      }}
      ref={ref}
    >
      <div>
        <Navigation />
        <LandingScreen />
        <SkillsScreen />
        <WorkScreen />
        <PhotosScreen isPreview />
        <ContactScreen />
        <ScrollRestoration />
      </div>
    </div>
  );
};

export default Main;
