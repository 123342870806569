import { FC } from 'react';
import { PhotoImageWrapper, PhotoImage } from './Photo.sc';
import useFadeIn from '../../hooks/useFadeIn';

interface Props {
  image: string;
}

const Photo: FC<Props> = ({ image }) => {
  const { ref, styles } = useFadeIn();

  return (
    <PhotoImageWrapper ref={ref} style={styles}>
      <PhotoImage
        src={`${process.env.PUBLIC_URL}/photography/${image}`}
        alt={image}
      />
    </PhotoImageWrapper>
  );
};

export default Photo;
