import styled from 'styled-components';
import { colours } from '../../constants';

export const PhotoHighlightWrapper = styled.div<{ $isActive: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colours.primaryLight};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  z-index: 10;
  pointer-events: ${(props) => (props.$isActive ? 'initial' : 'none')};
  opacity: ${(props) => (props.$isActive ? 1 : 0)};
  transition: opacity 0.3s linear;

  button {
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 32px;
    font-weight: 100;
  }
`;

export const PhotoHighlightImageWrapper = styled.div`
  width: calc(100% - 100px);
  max-width: 1400px;
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 50px;
  flex-direction: column;

  p {
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: bold;
  }
`;

export const PhotoHighlightImage = styled.img`
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 20px;
`;

export const PhotoHightlightCloseButton = styled.button`
  position: absolute;
  top: 50px;
  right: 50px;
`;
