import { styled } from 'styled-components';
import { colours } from '../../constants';

export const ExperienceScreenWrapper = styled.div`
  width: 100%;
  color: ${colours.primaryDark};
  max-width: 1400px;
  margin: 0 auto;
  padding: 150px 50px 0px;
`;

export const ExperienceScreenJobTitle = styled.h2`
  font-size: 2rem;
  font-weight: 500;
  color: ${colours.secondaryDark};
  margin-bottom: 10px;
`;

export const ExperienceScreenCompanyTitle = styled.h2`
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const ExperienceScreenDate = styled.h2`
  font-size: 1rem;
  color: ${colours.secondaryDark};
  margin-bottom: 10px;
`;

export const ExperienceScreenShortDescription = styled.h3`
  font-size: 1.5rem;
  margin-top: 100px;
  line-height: 2rem;
`;

export const ExperienceScreenLongDescription = styled.p`
  font-size: 1rem;
  margin-top: 25px;
  line-height: 1.5rem;
  color: ${colours.secondaryDark};
`;
