import { FC } from 'react';
import { NavigationItemWrapper } from './NavigationItem.sc';

interface Props {
  isLinkHome?: string;
  text: string;
}

const NavigationItem: FC<Props> = ({ isLinkHome, text }: Props) => {
  const handleClick = () => {
    const screen = document.getElementById(text.toLowerCase());
    if (screen !== null) {
      screen.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <NavigationItemWrapper onClick={handleClick}>
      {isLinkHome ? <a href={`/#${isLinkHome}`}>{text}</a> : <p>{text}</p>}
    </NavigationItemWrapper>
  );
};

export default NavigationItem;
