import { styled } from 'styled-components';
import { colours } from '../../constants';

export const ContactScreenWrapper = styled.div`
  width: 100%;
  background: ${colours.primaryDark};
`;

export const ContactScreenInner = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 150px 50px 100px;
  color: ${colours.primaryLight};
`;

export const ContactScreenTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: bold;
`;

export const ContactScreenSubtitle = styled.p`
  font-size: 1rem;
`;

export const ContactScreenLinks = styled.div`
  padding: 50px 0;

  p {
    color: ${colours.primaryLight};
    margin-bottom: 25px;
  }

  a {
    color: ${colours.primaryLight};
  }
`;
