import { styled } from 'styled-components';
import { colours } from '../../constants';

export const WorkScreenWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 150px 50px 0px;
`;

export const WorkScreenTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: bold;
`;

export const WorkScreenSubtitle = styled.h3`
  color: ${colours.secondaryDark};
  margin-bottom: 20px;
`;

export const WorkItems = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;
