import { FC } from 'react';
import {
  ExperienceScreenJobTitle,
  ExperienceScreenShortDescription,
  ExperienceScreenLongDescription,
  ExperienceScreenWrapper,
  ExperienceScreenCompanyTitle,
  ExperienceScreenDate,
} from './ExperienceScreen.sc';
import useFadeIn from '../../hooks/useFadeIn';
import { WorkItem } from '../../types';
import SkillsList from '../../components/SkillsList/SkillsList';

interface Props {
  workItem: WorkItem;
}

const ExperienceScreen: FC<Props> = ({ workItem }) => {
  const { ref, styles } = useFadeIn();

  return (
    <ExperienceScreenWrapper id="experience" ref={ref} style={styles}>
      <ExperienceScreenJobTitle>{workItem.jobTitle}</ExperienceScreenJobTitle>
      <ExperienceScreenCompanyTitle>
        {workItem.company}
      </ExperienceScreenCompanyTitle>
      <ExperienceScreenDate>{workItem.date}</ExperienceScreenDate>
      {workItem.skills.length > 0 && <SkillsList skills={workItem.skills} />}
      <ExperienceScreenShortDescription>
        {workItem.shortDescription}
      </ExperienceScreenShortDescription>
      <ExperienceScreenLongDescription>
        {workItem.longDescription}
      </ExperienceScreenLongDescription>
    </ExperienceScreenWrapper>
  );
};

export default ExperienceScreen;
