import {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  LandingScreenTitle,
  LandingScreenSubtitle,
  LandingScreenSubsubtitle,
  LandingScreenWrapper,
  LandingScreenPhoto,
} from './LandingScreen.sc';
import { throttle } from 'lodash';
import { isMobile } from 'react-device-detect';
import useFadeIn from '../../hooks/useFadeIn';

const LandingScreen: FC = () => {
  const { ref, styles } = useFadeIn();

  const timeout = useRef<NodeJS.Timeout>();
  const [position, setPosition] = useState<{ x: number; y: number } | null>(
    null
  );

  const onMouseMove: MouseEventHandler<HTMLSpanElement> = useCallback((e) => {
    if (isMobile) {
      return;
    }
    setPosition({ x: e.clientX, y: e.clientY });
  }, []);

  const onMouseLeave: MouseEventHandler<HTMLSpanElement> = useCallback(() => {
    if (isMobile) {
      return;
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      setPosition(null);
    }, 100);
  }, []);

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledOnMouseMove = useCallback(throttle(onMouseMove, 50), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledOnMouseLeave = useCallback(throttle(onMouseLeave, 50), []);

  return (
    <LandingScreenWrapper id="home" ref={ref} style={styles}>
      <LandingScreenTitle>Hello, I'm Dave 👋</LandingScreenTitle>
      <LandingScreenSubtitle>
        A creative software engineer working remotely from the EU, writing code
        across the entire tech stack.
      </LandingScreenSubtitle>
      <LandingScreenSubsubtitle>
        I'm also an <span>amateur photographer</span>,{' '}
        <span>budding novelist</span>, <span>snowboarder-in-training</span>,{' '}
        <span>husband</span>,{' '}
        <span
          onMouseMove={throttledOnMouseMove}
          onMouseOut={throttledOnMouseLeave}
        >
          dog parent
        </span>
        , and <span>miniature painter</span>. In no particular order, of course.
      </LandingScreenSubsubtitle>
      {position && !isMobile && (
        <LandingScreenPhoto
          style={{
            top: position.y - 275,
            left: position.x - 75,
            backgroundImage: `url(${process.env.PUBLIC_URL}/dog.jpg)`,
          }}
        />
      )}
    </LandingScreenWrapper>
  );
};

export default LandingScreen;
