import { FC } from 'react';
import useFadeIn from '../hooks/useFadeIn';
import { colours } from '../constants';
import Navigation from '../components/Navigation/Navigation';
import PhotosScreen from '../screens/PhotosScreen/PhotosScreen';

const Photos: FC = () => {
  const { ref, styles } = useFadeIn();

  return (
    <div
      style={{
        color: colours.primaryDark,
        ...styles,
      }}
      ref={ref}
    >
      <div>
        <Navigation subpage="photography" />
        <PhotosScreen />
      </div>
    </div>
  );
};

export default Photos;
