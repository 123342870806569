import { styled } from 'styled-components';
import { colours } from '../../constants';

export const LandingScreenWrapper = styled.div`
  width: 100%;
  color: ${colours.primaryDark};

  max-width: 1400px;
  margin: 0 auto;
  padding: 250px 50px 0px;
`;

export const LandingScreenTitle = styled.h1`
  font-size: 4rem;
  font-weight: 500;
`;

export const LandingScreenSubtitle = styled.h2`
  font-size: 2rem;
  margin-top: 100px;
  line-height: 2.5rem;
`;

export const LandingScreenSubsubtitle = styled.h3`
  font-size: 1.5rem;
  margin-top: 25px;
  line-height: 2rem;
  color: ${colours.secondaryDark};

  > span {
    &:hover {
      color: ${colours.primaryDark};
    }
  }
`;

export const LandingScreenPhoto = styled.div`
  position: fixed;
  transition-duration: 50ms;
  height: 250px;
  width: 150px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.7);
`;
