import { styled } from 'styled-components';
import { colours } from '../../constants';

export const WorkItemWrapper = styled.div`
  padding: 20px 0;
  width: calc((100% - 40px) / 3);

  @media (max-width: 1366px) {
    width: calc((100% - 20px) / 2);
  }

  @media (max-width: 920px) {
    width: 100%;
  }
`;

export const WorkItemImageWrapper = styled.button`
  width: 100%;
  aspect-ratio: 2;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  padding: 0px;
  outline: none;
  border: none;

  &:hover {
    cursor: pointer;

    img {
      transform: scale(1.1);
      filter: grayscale(0.3) brightness(1);
    }
  }
`;

export const WorkItemImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition-duration: 300ms;
  filter: grayscale(1) brightness(1);
  border-radius: 1rem;
`;

export const WorkItemDetails = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 25px;

  a {
    color: ${colours.primaryDark};
    text-decoration: none;
  }
`;

export const WorkItemTitle = styled.p`
  display: block;
  font-size: 1.5rem;
  line-height: 2rem;

  a {
    color: ${colours.primaryDark};
    text-decoration: none;
  }
`;

export const WorkItemJob = styled.p`
  line-height: 2rem;
`;

export const WorkItemDate = styled.p`
  line-height: 2rem;
  color: ${colours.secondaryDark};
  text-align: right;
`;
