import { FC } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './ErrorPage';
import Main from './pages/Main';
import Photos from './pages/Photos';
import Experience from './pages/Experience';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/photos',
    element: <Photos />,
  },
  {
    path: '/experience/:experience',
    element: <Experience />,
  },
]);

const Routes: FC = () => {
  return <RouterProvider router={router} />;
};

export default Routes;
