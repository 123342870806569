import { FC } from 'react';
import NavigationItem from '../NavigattionItem/NavigationItem';
import { NavigationWrapper, NavigationItems } from './Navigation.sc';

interface Props {
  subpage?: string;
}

const Navigation: FC<Props> = ({ subpage }) => {
  if (subpage) {
    return (
      <NavigationWrapper>
        <NavigationItems>
          <NavigationItem text="Home" isLinkHome={subpage} />
        </NavigationItems>
      </NavigationWrapper>
    );
  }

  return (
    <NavigationWrapper>
      <NavigationItems>
        <NavigationItem text="Home" />
        <NavigationItem text="Stack" />
        <NavigationItem text="Experience" />
        <NavigationItem text="Photography" />
        <NavigationItem text="Contact" />
      </NavigationItems>
    </NavigationWrapper>
  );
};

export default Navigation;
