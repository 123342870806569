import { FC, useEffect, useState } from 'react';
import { FiArrowLeft, FiArrowRight, FiMinimize2 } from 'react-icons/fi';
import {
  PhotoHighlightWrapper,
  PhotoHighlightImageWrapper,
  PhotoHighlightImage,
  PhotoHightlightCloseButton,
} from './PhotoHighlight.sc';

interface Props {
  image: string | null;
  onClose: () => void;
  onChangeImage: (direction: 'back' | 'forward') => void;
}

const PhotoHighlight: FC<Props> = ({ image, onChangeImage, onClose }) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const handleClose = () => {
    setIsActive(false);
  };

  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    handler: () => void
  ) => {
    e.stopPropagation();
    handler();
  };

  const handleTransitionEnd: React.TransitionEventHandler<HTMLDivElement> = (
    e
  ) => {
    if (e.propertyName === 'opacity' && !isActive) {
      onClose();
    }
  };

  useEffect(() => {
    if (image !== null) {
      setIsActive(true);
    }
  }, [image]);

  useEffect(() => {
    if (isActive) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'initial';
    }

    return () => {
      document.getElementsByTagName('html')[0].style.overflow = 'initial';
    };
  }, [isActive]);

  return (
    <PhotoHighlightWrapper
      $isActive={isActive}
      onClick={handleClose}
      onTransitionEnd={handleTransitionEnd}
    >
      <PhotoHightlightCloseButton
        onClick={(e) => handleButtonClick(e, () => handleClose())}
      >
        <FiMinimize2 />
      </PhotoHightlightCloseButton>
      <button
        onClick={(e) => handleButtonClick(e, () => onChangeImage('back'))}
      >
        <FiArrowLeft />
      </button>
      {image !== null && (
        <PhotoHighlightImageWrapper>
          <PhotoHighlightImage
            src={`${process.env.PUBLIC_URL}/photography/${image}`}
            alt={image}
            onClick={(e) => e.stopPropagation()}
          />
          <p>{image.split('.')[0].replace('_', ' ')}</p>
        </PhotoHighlightImageWrapper>
      )}
      <button
        onClick={(e) => handleButtonClick(e, () => onChangeImage('forward'))}
      >
        <FiArrowRight />
      </button>
    </PhotoHighlightWrapper>
  );
};

export default PhotoHighlight;
