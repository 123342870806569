import styled from 'styled-components';

export const PhotoImageWrapper = styled.div`
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;

  &:hover {
    cursor: pointer;

    img {
      transform: scale(1.1);
    }
  }
`;

export const PhotoImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition-duration: 300ms;
  border-radius: 1rem;
`;
