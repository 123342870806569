import { FC, useMemo, useState } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import {
  PhotosScreenSubtitle,
  PhotosScreenTitle,
  PhotosScreenWrapper,
  PhotosScreenPreview,
  PhotosScreenSeeMore,
} from './PhotosScreen.sc';
import images from '../../photographyList.js';
import useFadeIn from '../../hooks/useFadeIn';
import Photo from '../../components/Photo/Photo';
import PhotoHighlight from '../../components/PhotoHighlight/PhotoHighlight';

interface Props {
  isPreview?: boolean;
}

const PhotosScreen: FC<Props> = ({ isPreview }) => {
  const { ref, styles } = useFadeIn();
  const [selectedImage, setSelectedImage] = useState<number | null>(null);

  const handleChangeImage = (direction: 'back' | 'forward') => {
    if (selectedImage === null) return;

    const nextIndex =
      direction === 'back' ? selectedImage - 1 : selectedImage + 1;

    if (nextIndex < 0) {
      setSelectedImage(images.length - 1);
    } else if (nextIndex >= images.length) {
      setSelectedImage(0);
    } else {
      setSelectedImage(nextIndex);
    }
  };

  const imagesToShow = isPreview ? images.slice(0, 6) : images;

  const Gallery = useMemo(
    () => (
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 1400: 3 }}>
        <Masonry gutter="20px">
          {imagesToShow.map((image, index) =>
            isPreview ? (
              <a href="/photos" key={image}>
                <Photo image={image} />
              </a>
            ) : (
              <button
                key={image}
                style={{ outline: 'none', border: 'none', padding: '0px' }}
                onClick={() => setSelectedImage(index)}
              >
                <Photo image={image} />
              </button>
            )
          )}
        </Masonry>
      </ResponsiveMasonry>
    ),
    [imagesToShow, isPreview]
  );

  return (
    <PhotosScreenWrapper id="photography" ref={ref} style={styles}>
      {isPreview ? (
        <>
          <PhotosScreenTitle>Photography</PhotosScreenTitle>
          <PhotosScreenSubtitle>
            Sometimes, I try to take cool photos.
            <>
              Take a look at some of my shots <a href="/photos">here</a>
            </>
          </PhotosScreenSubtitle>
          <PhotosScreenPreview>
            {Gallery}
            <PhotosScreenSeeMore>
              <a href="/photos">Check out the full gallery</a>
            </PhotosScreenSeeMore>
          </PhotosScreenPreview>
        </>
      ) : (
        <>
          {Gallery}
          <PhotoHighlight
            image={selectedImage === null ? null : images[selectedImage]}
            onClose={() => setSelectedImage(null)}
            onChangeImage={handleChangeImage}
          />
        </>
      )}
    </PhotosScreenWrapper>
  );
};

export default PhotosScreen;
